/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import remark from "remark";
import remarkHTML from "remark-html";
import StyledLink from "./StyledLink";

const Sectionblock = ({ color, image, alt, title, text, href, linktext }) => {
  const value = remark().use(remarkHTML).processSync(text).toString();
  return (
    <section
      sx={{
        display: "flex",
        flexDirection: ["column", "row"],
        backgroundColor: color,
        color: "text",
        pb: [3, 0],
        "&:nth-of-type(2n+0)": {
          flexDirection: [null, "row-reverse"],
        },
      }}
    >
      <div
        sx={{
          flex: [1, 5],
          div: { height: "100%", minHeight: "400px" },
        }}
      >
        <PreviewCompatibleImage
          imageInfo={{
            image: image,
            alt: alt,
          }}
        />
      </div>
      <article
        sx={{
          flex: [1, 4],
          alignSelf: ["flex-start", "center"],
          height: "100%",
        }}
      >
        <div
          sx={{
            px: [4, 5, 6],
            my: [6],
            p: {
              ":last-of-type": {
                mb: 4,
              },
            },
          }}
        >
          <h2 sx={{ mt: "0px", fontSize: [5, 5, 6] }}>{title}</h2>
          <div
            sx={{ p: { my: 3 } }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
          {href ? <StyledLink href={href} text={linktext} /> : null}
        </div>
      </article>
    </section>
  );
};

export default Sectionblock;

Sectionblock.propTypes = {
  color: PropTypes.string,
};
