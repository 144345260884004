/** @jsx jsx */
import { jsx } from "theme-ui";
import { keyframes } from "@emotion/core";
import { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Sectionblock from "../components/Sectionblock";
import BackgroundImg from "../components/BackgroundImg";
import FeaturedProducts from "../components/FeaturedProducts";
import { Link } from "gatsby";

const Intro = keyframes`
    from {
      opacity: 0;
      animation-timing-function: ease-in-out;
    }
    80% {
      opacity: 1;
      animation-timing-function: ease-in-out;
    }
    to {
      opacity: 0;
      animation-timing-function: ease-in-out;
    }
    `;

export const IndexPageTemplate = ({
  title,
  image,
  productlink,
  projectlink,
  imageAlt,
  section,
  favouriteproductstitle,
  favouriteproducts,
}) => {
  return (
    <section>
      {image ? (
        <Link
          to={
            productlink
              ? `/products/${productlink}`
              : `/projects/${projectlink}`
          }
        >
          <BackgroundImg
            imageInfo={{
              image: image,
              alt: imageAlt,
              title: title,
            }}
          />
        </Link>
      ) : null}

      {section?.map((section) => {
        let link;
        if (section.brandpagelink === "true") {
          link = "/brand";
        } else if (section.projectspagelink === "true") {
          link = "/projects";
        } else if (section.productspagelink === "true") {
          link = "/products";
        } else if (section.servicespagelink === "true") {
          link = "/services";
        } else if (section.productlink === "true") {
          link = `/products/${section.productlink}`;
        } else if (section.projectlink === "true") {
          link = `/projects/${section.projectlink}`;
        }
        return (
          <Sectionblock
            key={section.title}
            section={section}
            image={section.image}
            alt={section.alt}
            title={section.title}
            color={"#fafafa"}
            text={section.body}
            href={link}
            linktext={section.linktitle}
          />
        );
      })}
      <div
        sx={{
          my: 8,
          mx: [4, 7],
        }}
      >
        {favouriteproducts && favouriteproducts.length > 0 && (
          <FeaturedProducts
            products={favouriteproducts}
            title={favouriteproductstitle}
          />
        )}
      </div>
    </section>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageAlt: PropTypes.string,
  section: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(false);

  useEffect(() => {
    const storageData = sessionStorage.getItem("introPlayed");
    if (storageData) {
      setResult(true);
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
      sessionStorage.setItem("introPlayed", true);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Fragment>
      {isLoading && !result ? (
        <div
          sx={{
            position: "relative",
            backgroundColor: "primary",
            height: "100vh",
            width: "100vw",
            zIndex: "100",
          }}
        >
          <div
            sx={{
              position: "absolute",
              display: "block",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              filter: "drop-shadow(0px 0px 80px rgba(255,255,255,0.9))",
              span: {
                color: "white",
                fontFamily: "heading",
                fontSize: [5, null, 9],
              },
            }}
          >
            <span
              sx={{
                animation: `${Intro}`,
                animationDuration: "3s",
              }}
            >
              HAHA studio
            </span>
          </div>
        </div>
      ) : null}
      <Layout>
        <IndexPageTemplate
          title={post.frontmatter.title}
          image={post.frontmatter.image}
          imageAlt={post.frontmatter.alt}
          section={post.frontmatter.section}
          favouriteproductstitle={post.frontmatter.favouriteproductstitle}
          favouriteproducts={post.frontmatter.favouriteproducts}
          productlink={post.frontmatter.productlink}
          projectlink={post.frontmatter.projectlink}
        />
      </Layout>
    </Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      id
      frontmatter {
        title
        productlink
        projectlink
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        alt
        favouriteproductstitle
        favouriteproducts
        section {
          title
          body
          linktitle
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          linktitle
          productlink
          projectlink
          projectspagelink
          productspagelink
          brandpagelink
          servicespagelink
        }
      }
    }
  }
`;
